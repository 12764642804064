<template>
  <header>
    <div class="top-header">
      <div class="inner-wrapper">
        <div class="logo-wrapper">
          <TeamfightLogo :size="3"/>
        </div>

        <SummonerSearch/>

        <div class="top-header__actions">
          <AdminOnly alwaysShow subtle>
            <div class="admin-mode-toggle">
              <a :href="`${getAdminUrl()}/home`" target="_blank">
                <strong>
                  Admin Mode
                </strong>
              </a>

              <UToggle v-model="userStore.adminMode" :ui="{ container: { base: 'dark:bg-white' } }" size="xl"/>
            </div>
          </AdminOnly>

          <NuxtLink class="discord" external href="https://discord.gg/3gDXwZ8pG9" target="_blank">
            <Icon color="white" name="discord" size="3.5rem"/>
          </NuxtLink>
        </div>
      </div>
    </div>

    <nav>
      <div class="inner-wrapper">
        <NavItem :to="{ name: getPageNames().ROUTE_HOME_NAME }" name="Home"/>

        <NavItem :to="{ name: getPageNames().ROUTE_STATS_COMPS_NAME }" isDropdownMenu matchPath="/stats/" name="Stats">
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Smolder')"
              :to="{ name: getPageNames().ROUTE_STATS_EXPLORER_NAME }"
              beta
              blurb="Time to sink yourself into the data and find the edge in your games!"
              isDropdownItem
              name="Explorer"
          />
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Nasus')"
              :to="{ name: getPageNames().ROUTE_STATS_COMPS_NAME }"
              blurb="How is your favourite comp stacking up against the competition?"
              isDropdownItem
              name="Comps"
          />
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Bard')"
              :to="{ name: getPageNames().ROUTE_STATS_CHAMPIONS_NAME }"
              blurb="Which champion has the best average placement? Time to find out."
              isDropdownItem
              name="Champions"
          />
          <NavItem
              :imageUrl="getTFTItemUrl('TFT_Item_Leviathan', 'TFTSet12')"
              :to="{ name: getPageNames().ROUTE_STATS_ITEMS_NAME }"
              blurb="Use your components wisely, know which Items perform the best!"
              isDropdownItem
              name="Items"
          />
          <NavItem
              :imageUrl="getTFTTraitUrl('TFT12_Eldritch')"
              :to="{ name: getPageNames().ROUTE_STATS_TRAITS_NAME }"
              blurb="Is it worth leveling up to hit your next Trait tier? We know the answer."
              imageSize="2.2rem"
              isDropdownItem
              name="Traits"
          />
          <NavItem
              :imageUrl="getTFTAugmentUrl('TFT_Augment_DarkAlleyDealings', 'TFTSet12')"
              :to="{ name: getPageNames().ROUTE_STATS_AUGMENTS_NAME }"
              blurb="Are your favourite Augments really helping or hurting you? Explore the stats here!"
              imageSize="2.4rem"
              isDropdownItem
              name="Augments"
          />
        </NavItem>

        <NavItem
            :to="{ name: getPageNames().ROUTE_LEADERBOARD_NAME, params: { queueType: 'ranked', region: getConventionalRegionName(RiotPlatformRegion.GLOBAL) } }"
            isDropdownMenu matchPath="/leaderboard/"
            name="Leaderboard"
        >
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Ryze')"
              :to="{ name: getPageNames().ROUTE_LEADERBOARD_NAME, params: { queueType: 'ranked', region: getConventionalRegionName(RiotPlatformRegion.GLOBAL) } }"
              blurb="Dominating ranked? See how high you can climb the leaderboard!"
              isDropdownItem
              name="Ranked Leaderboard"
          />
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Briar')"
              :to="{ name: getPageNames().ROUTE_LEADERBOARD_TOP_NAME, params: { queueType: 'ranked', region: getConventionalRegionName(RiotPlatformRegion.GLOBAL) } }"
              blurb="Who are the best players of the set? Here's who has held Rank 1 or Top 10 the longest!"
              isDropdownItem
              name="Top Players"
          />
        </NavItem>

        <NavItem
            :to="getChampionPageLink({ set: TFT_SET_12, championName: 'ashe' })" isDropdownMenu matchPath="/database/"
            name="Database"
        >
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Neeko')"
              :to="getChampionPageLink({ set: TFT_SET_12, championName: 'ashe' })"
              blurb="Champions are at the core of TFT, make sure you know who they are!"
              isDropdownItem
              name="Champions"
          />
          <NavItem
              :imageUrl="getTFTItemUrl('TFT_Item_JeweledGauntlet', 'TFTSet12')"
              :to="getItemPageLink({ set: TFT_SET_12, itemName: 'adaptive-helm' })"
              blurb="Items can turn a weak Champion into a beast, find the best to use here!"
              isDropdownItem
              name="Items"
          />
          <NavItem
              :imageUrl="getTFTAugmentUrl('TFT_Augment_OopsAllRageblades', 'TFTSet12')"
              :to="getAugmentPageLink({ set: TFT_SET_12, augmentName: 'afk' })"
              blurb="The right Augment can be the difference between 1st and 8th, check them all out here!"
              imageSize="2.4rem"
              isDropdownItem
              name="Augments"
          />
          <NavItem
              :imageUrl="getTFTChampionTileUrl('TFT12_Lillia')"
              :to="{ name: getPageNames().ROUTE_DATABASE_CHARMS_NAME }"
              blurb="Charms are powerful bonuses available in your shop, make sure you know them!"
              isDropdownItem
              name="Charms"
          />
        </NavItem>

        <NuxtLink :to="{ name: getPageNames().ROUTE_TEAM_BUILDER_NAME }" class="nav__team-builder">
          <TeamfightButton icon="i-heroicons-squares-2x2-solid">
            Team Builder
          </TeamfightButton>
        </NuxtLink>
      </div>
    </nav>
  </header>
</template>

<script lang="ts" setup>
import { getAdminUrl } from "~/helpers/getAdminUrl";
import { RiotPlatformRegion } from "~/types/riot";
import { useUserStore } from "~/stores/UserStore";
import { getConventionalRegionName } from "~/helpers/getConventionalRegionName";
import Icon from "~/Icon/Icon.vue";
import { TFT_SET_12 } from "~/data/sets";

const userStore = useUserStore();
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-direction: column;

  .top-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--TopNav-Height);
    background-color: var(--UI-Primary);
    padding: 0 5%;

    .inner-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: space-between;
      flex: 1;
      max-width: var(--View-MaxWidth);
      margin: 0 auto;

      .logo-wrapper {
        margin-right: auto;
      }

      .top-header__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 2rem;

        .admin-mode-toggle {
          display: flex;
          align-items: center;
          gap: 1rem;
          height: 1rem;
        }
      }
    }
  }

  nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--BottomNav-Height);
    background-color: var(--UI-Secondary);
    padding: 0 5%;

    .inner-wrapper {
      position: relative;
      display: flex;
      gap: 3rem;
      flex: 1;
      max-width: var(--View-MaxWidth);
      margin: 0 auto;
      height: 100%;
    }

    .nav__team-builder {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
}
</style>